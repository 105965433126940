<template>
	<div class="text-warning text-sm lh-xs hstack gap-1 align-items-start">
		<i class="bi bi-info-circle-fill"></i>
		<span>{{ text }}</span>
	</div>
</template>

<script>
	export default {
		props: {
			text: String
		}
	}
</script>